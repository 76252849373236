export const webDevelopment = [
  {
    id: "1",
    url: require(`../assets/WebWork3.jpg`),
    alt: "Web Work 3",
  },
  {
    id: "2",
    url: require(`../assets/webWork1.jpg`),
    alt: "Web Work 1",
  },
  {
    id: "3",
    url: require(`../assets/webWork2.jpg`),
    alt: "Web Work 2",
  },
  {
    id: "4",
    url: require(`../assets/maintainance.jpg`),
    alt: "Maintainance",
  }
];
