
export const mobileApp = [
  {
    id: "1",
    url: require(`../assets/mobile-3.jpg`),
    alt: "Supmedical",
  },
  {
    id: "2",
    url: require(`../assets/Mobile-4.jpg`),
    alt: "Mobilla App",
  },
  {
    id: "3",
    url: require(`../assets/Mobile-2.jpg`),
    alt: "Super School App",
  },
  {
    id: "4",
    url: require(`../assets/mobile-1.jpg`),
    alt: "Malakoot",
  },
];
