export const allWorks = [
  {
    id: "1",
    url: require(`../assets/mobile-1.jpg`),
    alt: "Malakoot",
  },
  {
    id: "2",
    url: require(`../assets/WebWork3.jpg`),
    alt: "Luxury App",
  },
  {
    id: "3",
    url: require(`../assets/Mobile-2.jpg`),
    alt: "Super School App",
  },
  {
    id: "4",
    url: require(`../assets/Web design.png`),
    alt: "Dashboard",
  },
  {
    id: "5",
    url: require(`../assets/Mobile-4.jpg`),
    alt: "Mobilla App",
  },
  {
    id: "6",
    url: require(`../assets/branding1.jpg`),
    alt: "Fashion Brand",
  },
  {
    id: "7",
    url: require(`../assets/mobile-3.jpg`),
    alt: "Supmedical",
  },
  {
    id: "8",
    url: require(`../assets/branding2.jpg`),
    alt: "Elomaraa",
  },
  {
    id: "9",
    url: require(`../assets/branding3.jpg`),
    alt: "Timeo Agency",
  },
  {
    id: "10",
    url: require(`../assets/branding4.jpg`),
    alt: "Hive Agency",
  },
  {
    id: "11",
    url: require(`../assets/webWork2.jpg`),
    alt: "Restaurant",
  },
  {
    id: "12",
    url: require(`../assets/maintainance.jpg`),
    alt: "Maintainance",
  },
];
