
export const Branding = [
  {
    id: "1",
    url: require(`../assets/branding2.jpg`),
    alt: "Elomaraa",
  },
  {
    id: "2",
    url: require(`../assets/branding3.jpg`),
    alt: "Timeo Agency",
  },
  {
    id: "3",
    url: require(`../assets/branding4.jpg`),
    alt: "Hive Agency",
  },
  {
    id: "4",
    url: require(`../assets/branding1.jpg`),
    alt: "Fashion Brand",
  },
];