export const webDesign = [
  {
    id: "1",
    url: require(`../assets/Web design.png`),
    alt: "Dashboard",
  },
  {
    id: "2",
    url: require(`../assets/webDesign2.png`),
    alt: "Y3 App",
  },
  {
    id: "3",
    url: require(`../assets/webDesgin3.png`),
    alt: "Hotel App",
  },
  {
    id: "4",
    url: require(`../assets/webDesign4.png`),
    alt: "Y3",
  },
  {
    id: "5",
    url: require(`../assets/maintainance.jpg`),
    alt: "Maintainance",
  },
];
